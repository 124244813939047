module.exports = {
  siteTitle: 'Max Pagliuca',
  shortTitle: 'Web Resume',
  siteDescription: `A passion for design and automated manufacturing`,
  keyWords: ['SolidWorks', 'CAD', 'CNC Machining'],
  authorName: 'Max Pagliuca',
  twitterUsername: 'null',
  githubUsername: 'maxpagliuca',
  authorAvatar: '/images/avatar.jpeg',
  authorDescription: `CAD Designer and CNC machinist, passionate about what I do. Interested in technology for as long I can remember, I started to study automated manufacturing as a hobby shortly after high school. <br />
    In 2011 I started working as CAD designer and laser operator for a steel rule die manufacturer.
    In 2016, I wanted to expand my knowledge and skills, so I went to school to learn CAD, manual & CNC machining, and other advanced manufacturing techniques. <br />
    I currently work as an Engineering Technician and mainly use <strong>SolidWorks, Fusion 360 & CNC Milling machines.</strong>`,
  skills: [
    {
      name: 'SolidWorks',
      level: 70
    },
    {
      name: 'AutoCAD 2D',
      level: 60
    },
    {
      name: 'Creo Parametric',
      level: 40
    },
    {
      name: 'Fusion 360',
      level: 55
    },
    {
      name: 'Mastercam',
      level: 50
    },
    {
      name: 'Manual Machining',
      level: 40
    },
    {
      name: 'Additive Manufacturing',
      level: 65
    }/*,
    {
      name: 'Photoshop',
      level: 60
    },
    {
      name: 'Illustrator',
      level: 45
    }*/
  ],
  jobs: [
    {
      company: "MOAB mfg",
      begin: {
        month: '2018',
        year: 'mar'
      },
      duration: null,
      occupation: "Engineering Technician",
      description: "Design, prototype, and test firearm components. Program toolpaths for CNC mills and design engraving graphics for a fiber engraving laser, as well as operate those machines. Design and layout graphics for product packaging."
  
    }, {
      company: "Pikes Peak Community College",
      begin: {
        month: '2017',
        year: 'feb'
      },
      duration: '1 year & 5 months',
      occupation: "3D Printing Lab Technician",
      description: "Operated and maintained professional 3D printers for the Advanced Manufacturing program. Designed and farbicated parts, assemblies and blueprints for instructors and machining classes. Lead tours for potential students and commercial partners. "
    }, {
      company: "ABC Die Company",
      begin: {
        month: '2011',
        year: 'dec'
      },
      duration: '2 Years',
      occupation: "CAD & CNC Operator",
      description: "Designed steel rule die tooling from specifications and blueprints. Created samples, transparencies and prototypes on plotting / cutting tables. Programmed, operated and maintained die board cutting and engraving lasers, CNC router."
  
    },
    /* ... */
  ],
  social: {
    twitter: "null",
    linkedin: "https://www.linkedin.com/in/max-pagliuca",
    github: "null",
    email: "max.pagliuca@gmail.com"
  },
  siteUrl: 'https://max.pagliu.ca',
  pathPrefix: '/', // Note: it must *not* have a trailing slash.
  siteCover: '/images/cover.jpeg',
  googleAnalyticsId: 'UA-151260943-2',
  background_color: '#fafafa',
  theme_color: '#241036',
  display: 'minimal-ui',
  icon: 'src/assets/gatsby-icon.png',
  headerLinks: [
    {
      label: 'Max Pagliuca',
      url: '/',
    }
  ]
}