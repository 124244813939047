import React from 'react'
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import { FaGithub, FaLinkedin, FaEnvelope, FaTwitter } from "react-icons/fa"
import siteConfig from '../../data/siteConfig'

import Layout from '../components/layout'
import Hero from '../components/hero'
import SEO from '../components/SEO'
import Wrapper from '../components/wrapper'
import About from '../components/about'
import Skills from '../components/skills'
import Timeline from '../components/timeline'
//import Repositories from '../components/repositories'

const Separator = styled.hr`
  margin-top: 24px;
  margin-bottom: 16px;
`
class Home extends React.Component {
  render () {
    // validate siteConfig settings
    if (siteConfig.googleAnalyticsId === 'UA-000000000-1') {
      console.error('WARNING: Please set a proper googleAnalyticsId. See https://analytics.google.com for details.');
    }

    const title = siteConfig.siteTitle
    const {keywords} = siteConfig
    return (
      <Layout location={this.props.location}>
        <SEO
          title={title}
          keywords={keywords}
        />

        <Hero
          heroImg={siteConfig.siteCover}
          title={title}
        />

        <Wrapper className={this.props.className} >
          <Container className="page-content" fluid>
            <Row>
              <Col xs={4} className='avatar'>
                <img
                  className='avatar__image'
                  src='/images/avatar.jpeg'
                  alt='user avatar'
                />
                <div className="social">
                  {siteConfig.social.github && <a className="social-link github" aria-label="GitHub" href={siteConfig.social.github}>
                    <FaGithub className="social-icon" size="32" />
                  </a>}
                  {siteConfig.social.linkedin && <a className="social-link linkedin"  aria-label="LinkedIn" href={siteConfig.social.linkedin}>
                    <FaLinkedin className="social-icon" size="32" />
                  </a>}
                  {siteConfig.social.twitter && <a className="social-link twitter" aria-label="Twitter" href={siteConfig.social.twitter}>
                    <FaTwitter className="social-icon" size="32" />
                  </a>}
                  {siteConfig.social.email && <a className="social-link email" aria-label="Email" href={`mailto:${siteConfig.social.email}`}>
                    <FaEnvelope className="social-icon" size="32" />
                  </a>}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={4} sm={4}>
                <About title='About' text={siteConfig.authorDescription}/>
              </Col>
              <Col xs={4} sm={4}>
                <Skills title='Skills' skills={siteConfig.skills} />
              </Col>
            </Row>
            <Separator />
            <Timeline />
            <Separator />
           {/* <Repositories /> */}
           <h1>Projects</h1>
           <br />
            <Img fluid={this.props.data.imageOne.childImageSharp.fluid} />
            <br />
            <Img fluid={this.props.data.imageTwo.childImageSharp.fluid} />
            <br />
            <Img fluid={this.props.data.imageThree.childImageSharp.fluid} />
            <br />
            <Img fluid={this.props.data.imageFour.childImageSharp.fluid} />
            <br />
            <Img fluid={this.props.data.imageFive.childImageSharp.fluid} />
            <br />
            <Img fluid={this.props.data.imageSix.childImageSharp.fluid} />
            <br />
            <Img fluid={this.props.data.imageSeven.childImageSharp.fluid} />
            <br />
            <Img fluid={this.props.data.imageEight.childImageSharp.fluid} />
          </Container>
        </Wrapper>
      </Layout>
    )
  }
}

export default styled(Home)`
  .page-content {
    max-width: 100%;
    margin-bottom: 40px;
  }

  .avatar {
    align-items: center;
  margin-bottom: 24px;
  }

  .avatar__image {
    box-shadow: 3px 3px 15px 0px rgba(0,0,0,0.75);
    max-width: 200px;
    border-radius: 100px;
    margin: 0 auto 24px;
  }

  .social {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .social-link {
    padding: 8px;
    color: #555;
  }

  a.social-link.twitter:hover {
    color: #1da1f2;
  }

  a.social-link.github:hover {
    color: #24292e;
  }

  a.social-link.linkedin:hover {
    color: #0077B5;
  }

  a.social-link.email:hover {
    color: #c23a2b;
  }
`

export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 1000) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;


export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "one.jpg" }) {
      ...fluidImage
    }
    imageTwo: file(relativePath: { eq: "two.jpg" }) {
      ...fluidImage
    }
    imageThree: file(relativePath: { eq: "three.jpg" }) {
      ...fluidImage
    }
    imageFour: file(relativePath: { eq: "four.jpg" }) {
      ...fluidImage
    }
    imageFive: file(relativePath: { eq: "five.jpg" }) {
      ...fluidImage
    }
    imageSix: file(relativePath: { eq: "six.jpg" }) {
      ...fluidImage
    }
    imageSeven: file(relativePath: { eq: "seven.jpg" }) {
      ...fluidImage
    }
    imageEight: file(relativePath: { eq: "eight.jpg" }) {
      ...fluidImage
    }
  }
`